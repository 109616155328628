import './ContractGen.css'
import { useState, useCallback } from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark, atelierCaveDark} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import debounce from 'lodash.debounce';
import Navbar from "./Header.js"


function ContractGen() {

    
    

    const [contractName, setContractName] = useState('LazyDevs')
    const [collectionName, setCollectionName] = useState('Lazy Devs')
    const [collectionSymbol, setCollectionSymbol] = useState('LDS')
    

    const [mintAll, setMintAll] = useState(false);
    const [hidden, setHidden] = useState(null);
    const [whitelist, setWhitelist] = useState(null);
    const [airdrop, setAirdrop] = useState(null);



    const onContractName = (event) => {
        const { value } = event.target
        setContractName(value)
    }

    const onCollectionName = (event) => {
        const { value } = event.target
        setCollectionName(value)
    }

    const onCollectionSymbol = (event) => {
        const { value } = event.target
        setCollectionSymbol(value)
    }

    const checkboxFunciton = (a,b,c) => {
    var id = document.getElementById(a);
    var text = document.getElementById(b);
    var text2 = document.getElementById(c);
    
    if (id.checked == true){
        text.forEach(t => {
            t.style.display = "block";
        })
        text2.forEach(t => {
            t.style.display = "none";
        })
    } else {
        text.forEach(t => {
            t.style.display = "none";
        })
        text2.forEach(t => {
            t.style.display = "block";
        })
    }
    
    }



    const MintAll = () => {
        var checkBox = document.getElementById("option_1");
        var text = document.querySelectorAll(".MintAll");
        var mint = document.querySelectorAll(".mintFunction");


        if (checkBox.checked == true){
            text.forEach(t => {
                t.style.display = "block";
            })
            mint.forEach(t => {
                t.style.display = "none";
            })
        } else {
            text.forEach(t => {
                t.style.display = "none";
            })
            mint.forEach(t => {
                t.style.display = "block";
            })
        }
    }

    const Whitelist = () => {
        var checkBox = document.getElementById("option_2");
        var text = document.querySelectorAll(".Whitelist");
        var text_inline = document.querySelectorAll(".Whitelist_inline");

        if (checkBox.checked == true){
            text.forEach(t => {
                t.style.display = "block";
            })
            text_inline.forEach(t => {
                t.style.display = "inline";
            })
        } else {
            text.forEach(t => {
                t.style.display = "none";
            })
            text_inline.forEach(t => {
                t.style.display = "none";
            })
        }
    }

    const Hidden = () => {
        var checkBox = document.getElementById("option_3");
        var text = document.querySelectorAll(".Reveal");
        var text_inline = document.querySelectorAll(".Reveal_inline");


        if (checkBox.checked == true){
            text.forEach(t => {
                t.style.display = "block";
            })
            text_inline.forEach(t => {
                t.style.display = "inline";
            })
        } else {
            text.forEach(t => {
                t.style.display = "none";
            })
            text_inline.forEach(t => {
                t.style.display = "none";
            })
        }
    }

    const buttonERC721 = () => {
        var erc721 = document.querySelectorAll(".erc721");
        var erc721a = document.querySelectorAll(".erc721a");
        var erc20 = document.querySelectorAll(".erc20");
        var erc1155 = document.querySelectorAll(".erc1155");

        erc721.forEach(t => {
            t.style.display = "block";
        })

        erc721a.forEach(t => {
            t.style.display = "none";
        })

        erc20.forEach(t => {
            t.style.display = "none";
        })

        erc1155.forEach(t => {
            t.style.display = "none";
        })
        

    }

    const buttonERC721A = () => {
        var erc721 = document.querySelectorAll(".erc721");
        var erc721a = document.querySelectorAll(".erc721a");
        var erc20 = document.querySelectorAll(".erc20");
        var erc1155 = document.querySelectorAll(".erc1155");

        erc721.forEach(t => {
            t.style.display = "none";
        })

        erc721a.forEach(t => {
            t.style.display = "block";
        })

        erc20.forEach(t => {
            t.style.display = "none";
        })

        erc1155.forEach(t => {
            t.style.display = "none";
        })
    }

    const buttonERC20 = () => {
        var erc721 = document.querySelectorAll(".erc721");
        var erc721a = document.querySelectorAll(".erc721a");
        var erc20 = document.querySelectorAll(".erc20");
        var erc1155 = document.querySelectorAll(".erc1155");

        erc721.forEach(t => {
            t.style.display = "none";
        })

        erc721a.forEach(t => {
            t.style.display = "none";
        })

        erc20.forEach(t => {
            t.style.display = "block";
        })

        erc1155.forEach(t => {
            t.style.display = "none";
        })
    }

    const buttonERC1155 = () => {
        var erc721 = document.querySelectorAll(".erc721");
        var erc721a = document.querySelectorAll(".erc721a");
        var erc20 = document.querySelectorAll(".erc20");
        var erc1155 = document.querySelectorAll(".erc1155");


        erc721.forEach(t => {
            t.style.display = "none";
        })

        erc721a.forEach(t => {
            t.style.display = "none";
        })

        erc20.forEach(t => {
            t.style.display = "none";
        })

        erc1155.forEach(t => {
            t.style.display = "block";
        })
    }


    const debouncedContractName = useCallback(debounce(onContractName, 300), []);
    const debouncedCollectionName = useCallback(debounce(onCollectionName, 300), []);
    const debouncedCollectionSymbol = useCallback(debounce(onCollectionSymbol, 300), []);






  return (
    <>

        <Navbar/>
      <h1>NFT Contract Generator (BETA)</h1>

        <div className="container">
            <div className='ContractGen'>
                <div className='Select'>
                    <div className='Header'>
                        <button onClick={buttonERC721A}>ERC721A</button>
                        <button onClick={buttonERC721}>ERC721</button>
                        {/* <button onClick={buttonERC20}>ERC20</button>
                        <button onClick={buttonERC1155}>ERC1155</button> */}
                    </div>

                    <div className='options'>
                        <div className='nftCollection' style={{display: "block"}}>
                            <div>
                            <input type="text" id='ContractName' placeholder='Contract Name' onChange={debouncedContractName}></input><br/>
                            </div>

                            <div>
                            <input type="text" id='CollectionName' placeholder='Collection Name' onChange={debouncedCollectionName}></input><br/>
                            </div>
                            
                            <div>
                            <input type="text" id='SollectionSymble' placeholder='Collection Symbol' onChange={debouncedCollectionSymbol}></input><br/>
                            </div>                


                            <div className='checkbox'>
                            <input type="checkbox" id="option_1" onClick={() => {
                                setMintAll(!mintAll);
                            }}/>
                            <label for="option_1"> Mint All</label><br/>
                            </div>



                            <div className='checkbox'>
                            <input type="checkbox" id="option_2" onClick={() => {
                                setWhitelist(!whitelist)
                            }}/>
                            <label for="option_2"> MerkleTree (Whitelist)</label><br/>
                            </div>


                            <div className='checkbox'>
                            <input type="checkbox" id="option_3" onClick={() => {
                                setHidden(!hidden)
                            }}/>
                            <label for="option_3"> Hidden</label><br/>
                            </div>

                            {/* <div className='checkbox'>
                            <input type="checkbox" id="option_4"  onClick={() => {
                                setAirdrop(!airdrop)
                            }}/>
                            <label for="option_4"> Airdrop</label><br/>
                            </div> */}
                        </div>

                        <div className='erc20' style={{display: "none"}}>
                            <div>

                            </div>
                        </div>
                    </div>


                    <div style={{textAlign: "center"}}>
                    <hr />
                        <h4>Powered by LazyDevs™</h4>
                        <p>Help us by donating to this wallet: 0x1C0A6c9b0622419EE940Db940b1dE9415F89ed97</p>
                        <p>We accept test-net tokens ;)</p>
                    </div>



                </div>

                <div className='Output'>
                    <div className='erc721a'>
                <SyntaxHighlighter language="solidity" style={a11yDark} showLineNumbers= "true"> 
                
                {`
// SPDX-License-Identifier: MIT

//Powered By LazyDevs
//!Disclaimer!
//    please review this code on your own before using any of
//    the following code for production.
//    LazyDevs will not be liable in any way if for the use 
//    of the code. That being said, the code has been tested 
//    to the best of the developers' knowledge to work as intended.
//    If you find any problems please let the dev know in order to improve
//    the contract and fix vulnerabilities if there is one.

pragma solidity ^0.8.10;

import "@openzeppelin/contracts/access/Ownable.sol";
import "erc721a/contracts/ERC721A.sol";
import "@openzeppelin/contracts/utils/Strings.sol";
${whitelist ? 'import "@openzeppelin/contracts/utils/cryptography/MerkleProof.sol";' : ""}
import "@openzeppelin/contracts/security/ReentrancyGuard.sol";


contract ${contractName} is ERC721A, Ownable, ReentrancyGuard {

    using Strings for uint256;
    

    uint256 price;
    uint256 _maxSupply;
    uint256 maxMintAmountPerTx;
    uint256 maxMintAmountPerWallet;

    string baseURL = "";
    string ExtensionURL = ".json";
    ${hidden ? "string HiddenURL;":""}


    ${whitelist ? "bool whitelistFeature = false;":""}
    ${whitelist ? "bytes32 hashRoot;": ""}
    bool paused = false;
    ${hidden ? "bool revealed = false;":""}

    constructor(uint256 _price, uint256 __maxSupply, string memory _initBaseURI, uint256 _maxMintAmountPerTx, uint256 _maxMintAmountPerWallet${hidden ? ", string memory _initNotRevealedUri":""}${whitelist ? ", bytes32 _hashroot":""}) ERC721A("${collectionName}", "${collectionSymbol}") {

        baseURL = _initBaseURI;
        price = _price;
        _maxSupply = __maxSupply;
        maxMintAmountPerTx = _maxMintAmountPerTx;
        maxMintAmountPerWallet = _maxMintAmountPerWallet;
        ${hidden ? "HiddenURL = _initNotRevealedUri;":""}
        ${whitelist ? "hashRoot = _hashroot;":""}
        ${mintAll ? `_safeMint(msg.sender, __maxSupply);`: ""}
    }

    // ================== Mint Function =======================

      function mint(address to, uint256 _mintAmount${whitelist ? ", bytes32[] calldata _merkleProof":""}) public payable{
          require(!paused, "The contract is paused!");
          require(_mintAmount > 0 && _mintAmount <= maxMintAmountPerTx, "Invalid mint amount!");
          require(totalSupply() + _mintAmount <= _maxSupply, "Max supply exceeded!");
          require(msg.value >= price * _mintAmount, "You dont have enough funds!");
          require(balanceOf(msg.sender) + _mintAmount <= maxMintAmountPerWallet, "Max mint per wallet exceeded!");
          
          ${whitelist ? `if(whitelistFeature){
            require(checkHashProof(_merkleProof), "You are not whitelisted!");
        }`:""}
    
    

          _safeMint(to, _mintAmount);
      }
    // ================== Orange Functions (Owner Only) ===============

    function pause(bool state) public onlyOwner {
        paused = state;
    }

    function safeMint(address to, uint256 quantity) public onlyOwner {
        _safeMint(to, quantity);
    }
    ${hidden ? `
    function setHiddenURL(string memory uri) public onlyOwner {
        HiddenURL = uri;
    }
    
    function setRevealed(bool _state) public onlyOwner {
        revealed = _state;
    }
    `:""}
    function setbaseURL(string memory uri) public onlyOwner{
        baseURL = uri;
    }

    function setExtensionURL(string memory uri) public onlyOwner{
        ExtensionURL = uri;
    }

    function setCostPrice(uint256 _cost) public onlyOwner{
        price = _cost;
    } 

    function setSupply(uint256 supply) public onlyOwner{
        _maxSupply = supply;
    }
    ${whitelist ? `
    // ====================== Whitelist Feature ============================

    function setwhitelistFeature(bool state) public onlyOwner{
        whitelistFeature = state;
    }

    function setHashRoot(bytes32 hp)public onlyOwner{
        hashRoot = hp;
    }

    function checkWhitelist() public view returns (bool){
        return whitelistFeature;
    }

    function checkHashRoot() view public onlyOwner returns (bytes32){
        return hashRoot;
    }

    function checkHashProof(bytes32[] calldata _merkleProof) view internal returns (bool){

        bytes32 leaf = keccak256(abi.encodePacked(msg.sender));

        if(MerkleProof.verify(_merkleProof, hashRoot, leaf)){
            return true;
        }

        return false;
    }
    `:""}
    // ================================ Withdraw Function ====================

    function withdraw() public onlyOwner nonReentrant{

        uint256 CurrentContractBalance = address(this).balance;
        (bool os, ) = payable(owner()).call{value: CurrentContractBalance}("");
        require(os);

    }

    // =================== Blue Functions (View Only) ====================

    function tokenURI(uint256 tokenId) public view override(ERC721A) returns (string memory){

        require(_exists(tokenId),"ERC721Metadata: URI query for nonexistent token");
        ${hidden ? `
        if (revealed == false) {
        return HiddenURL;
        }
        `:""}
        string memory currentBaseURI = _baseURI();
        return bytes(currentBaseURI).length > 0
            ? string(abi.encodePacked(currentBaseURI, tokenId.toString(), ExtensionURL))
            : '';

    }

    function cost() public view returns (uint256){
        return price;
    }

    function _baseURI() internal view virtual override returns (string memory) {
        return baseURL;
    }

    function maxSupply() public view returns (uint256){
        return _maxSupply;

    }  
}
//Powered By LazyDevs
`}
                </SyntaxHighlighter> 
                    </div>
                    <div className='erc721' style={{display: "none"}}>
                    <SyntaxHighlighter language="solidity" style={a11yDark}>
                        {`
// SPDX-License-Identifier: MIT

//Powered By LazyDevs
//!Disclaimer!
//    please review this code on your own before using any of
//    the following code for production.
//    LazyDevs will not be liable in any way if for the use 
//    of the code. That being said, the code has been tested 
//    to the best of the developers' knowledge to work as intended.
//    If you find any problems please let the dev know in order to improve
//    the contract and fix vulnerabilities if there is one.


pragma solidity ^0.8.10;

import "@openzeppelin/contracts/token/ERC721/ERC721.sol";
import "@openzeppelin/contracts/access/Ownable.sol";
import "@openzeppelin/contracts/utils/Counters.sol";
${whitelist ? 'import "@openzeppelin/contracts/utils/cryptography/MerkleProof.sol";':""}
import "@openzeppelin/contracts/security/ReentrancyGuard.sol";

contract ${contractName} is ERC721, Ownable, ReentrancyGuard {
    using Strings for uint256;
    using Counters for Counters.Counter;

    uint256 price;
    uint256 _maxSupply;
    uint256 maxMintAmountPerTx;
    uint256 maxMintAmountPerWallet;

    string baseURL = "";
    string ExtensionURL = ".json";
    ${hidden ? "string HiddenURL;":""}

    ${whitelist ? "bool whitelistFeature = false;":""}
    ${whitelist ? "bytes32 hashRoot;" : ""}
    ${hidden ? "bool revealed = false;" : ""}
    bool paused = false;

    Counters.Counter private _tokenIdCounter;
    
    constructor(uint256 _price, uint256 __maxSupply, string memory _initBaseURI, uint256 _maxMintAmountPerTx, uint256 _maxMintAmountPerWallet${hidden ? ", string memory _initNotRevealedUri":""}${whitelist ? ", bytes32 _hashroot":""}) ERC721("${collectionName}", "${collectionSymbol}") {

        baseURL = _initBaseURI;
        price = _price;
        _maxSupply = __maxSupply;
        maxMintAmountPerTx = _maxMintAmountPerTx;
        maxMintAmountPerWallet = _maxMintAmountPerWallet;
        ${hidden ? "HiddenURL = _initNotRevealedUri;":""}
        ${whitelist ? "hashRoot = _hashroot;":""}

        ${mintAll ? `for (uint256 i = 1; i <= _maxSupply; i++) {
            _tokenIdCounter.increment();
            safeMint(msg.sender);

        }`:""}
    }

    // ================= Mint Function =======================

    function Mint(address to, uint256 _mintAmount${whitelist ?  ", bytes32[] calldata _merkleProof":""}) public payable{
        uint256 tokenId = _tokenIdCounter.current();
        require(!paused, "The contract is paused!");
        require(_mintAmount > 0 && _mintAmount <= maxMintAmountPerTx, "Invalid mint amount!");
        require(currentSupply() + _mintAmount <= _maxSupply, "Max supply exceeded!");
        require(msg.value >= price * _mintAmount, "You dont have enough funds!");
        require(balanceOf(msg.sender) + _mintAmount <= maxMintAmountPerWallet, "Max mint per wallet exceeded!");
        
        ${whitelist ? `if(whitelistFeature){
            require(checkHashProof(_merkleProof), "You are not whitelisted!");
        }`:""}

        _tokenIdCounter.increment();
        _safeMint(to, tokenId);
    }

        ${whitelist ? `// ====================== Whitelist Feature ============================

        function setwhitelistFeature(bool state) public onlyOwner{
            whitelistFeature = state;
        }
    
        function setHashRoot(bytes32 hp)public onlyOwner{
            hashRoot = hp;
        }
    
        function checkHashRoot() view public onlyOwner returns (bytes32){
            return hashRoot;
        }

        function checkWhitelist() public view returns (bool){
            return whitelistFeature;
        }
    
        function checkHashProof(bytes32[] calldata _merkleProof) view internal returns (bool){
    
            bytes32 leaf = keccak256(abi.encodePacked(msg.sender));
    
            if(MerkleProof.verify(_merkleProof, hashRoot, leaf)){
                return true;
            }
    
            return false;
        }`:""}

    // =================== Orange Functions (Owner Only) ===============

    function pause(bool state) public onlyOwner {
        paused = state;
    }

    function safeMint(address to) public onlyOwner {
        uint256 tokenId = _tokenIdCounter.current();
        _tokenIdCounter.increment();
        _safeMint(to, tokenId);
    }

    ${airdrop ? `function airDrop(address[] memory to) public onlyOwner{
        for (uint256 i = 1; i <= to.length; i++) {
            safeMint(to[i]);
        }
    }`:""}

    ${hidden ? `function setHiddenURL(string memory uri) public onlyOwner {
        HiddenURL = uri;
    }` : ""}

    function setbaseURL(string memory uri) public onlyOwner{
        baseURL = uri;
    }

    function setExtensionURL(string memory uri) public onlyOwner{
        ExtensionURL = uri;
    }

    // ================== Withdraw Function =============================
    function withdraw() public onlyOwner nonReentrant{
        uint256 CurrentContractBalance = address(this).balance;

        (bool os, ) = payable(owner()).call{value: CurrentContractBalance}("");
        require(os);

    }

    // =================== Blue Functions (View Only) ====================

    function tokenURI(uint256 tokenId)public view virtual override returns (string memory){
        require(_exists(tokenId),"ERC721Metadata: URI query for nonexistent token");

    ${hidden ? `if (revealed == false) {
        return HiddenURL;
    }`:""}

        string memory currentBaseURI = _baseURI();
        return bytes(currentBaseURI).length > 0
            ? string(abi.encodePacked(currentBaseURI, tokenId.toString(), ExtensionURL))
            : '';
    }

    function cost() public view returns (uint256){
        return price;
    }

    function _baseURI() internal view virtual override returns (string memory) {
    return baseURL;
    }
    function currentSupply() public view returns (uint256){
        return _tokenIdCounter.current();
    }

    function maxSupply() public view returns (uint256){
        return _maxSupply;
    }

    function setCostPrice(uint256 _cost) public onlyOwner{
        price = _cost;
    } 

    function setSupply(uint256 supply) public onlyOwner{
        _maxSupply = supply;
    }
    
    // ================ Internal Functions ===================

    function _beforeTokenTransfer(address from, address to, uint256 tokenId) internal override{
        super._beforeTokenTransfer(from, to, tokenId);
    }

}
//Powered By LazyDevs
                        `}
                        </SyntaxHighlighter>
                    </div>
                </div>        
            </div>
        </div>
    </>
  )
}

export default ContractGen