import React from 'react'
import {FaDiscord} from 'react-icons/fa'

function Navbar() {
  return (
    <div>
    
    <div className="icons">

      <div className='discord'>
        <a href="https://discord.gg/WqfEGZQJdz" target="_blank"> <FaDiscord/> </a>
      </div>

    </div>
    
    
    
    </div>
  )
}

export default Navbar